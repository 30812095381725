import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const MyIframeComponent = () => {
  return (
    <iframe 
      src="https://tiny-flan-685d2e.netlify.app/" 
      style={{
        maxWidth: '100%',
        minHeight: '800px',
        width: '100%',
        height: 'auto',
        border: 'none'
      }}
      title="Resume analyzer"
    ></iframe>
  );
};


const AiResumeAnalyzer = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <div>
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-1'>{post.frontmatter.title}</h1>
                  <h2 className='description has-text-white is-size-3'>
                    {post.frontmatter.description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <MyIframeComponent />
            </div>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  )
}

AiResumeAnalyzer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AiResumeAnalyzer

export const AiResumeAnalyzerQuery = graphql`
  query AiResumeAnalyzer($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        meta_title
        meta_description
      }
    }
  }
`
